import React from 'react'
import { graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import RepoCard from 'components/RepoCard'
import SEO from 'components/Seo'
import { IntlProvider } from 'contexts/intl/IntlContext'

export const query = graphql`
  query TopicPostByID($topic: [String]!) {
    file(relativePath: { eq: "joshua-reddekopp-syymxsdnj54-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
        resize {
          height
          width
        }
      }
    }
    allMarkdownRemark(
      sort: { frontmatter: { publishedAt: DESC } }
      filter: { frontmatter: { topics: { in: $topic } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            publishedAt(formatString: "MMMM DD, YYYY")
            title
            description
            topics
          }
        }
      }
    }
  }
`

const TopicPost = ({ data, location }) => {
  const topicId = location.pathname.replace('/topics/', '').replace('/', '')
  const customTopic = topicId
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

  const postsLocale = data.allMarkdownRemark.edges.map(({ node }) => node)

  return (
    <IntlProvider pathname={location.pathname}>
      {({ locale, formatDate, formatMessage, getPath }) => (
        <Layout>
          <SEO
            lang={locale}
            title={`${customTopic} | ${formatMessage({ id: 'topics.title' })}`}
            description={formatMessage({ id: `${topicId}.description` })}
            keywords={formatMessage({ id: `${topicId}.keywords` })}
            isBlogPost={false}
            ogTitle={`${customTopic} | ${formatMessage({ id: 'topics.title' })}`}
            ogDescription={formatMessage({ id: `${topicId}.description` })}
            ogKeywords={formatMessage({ id: `${topicId}.keywords` })}
            ogLocale={locale}
            twitterTitle={`${customTopic} | ${formatMessage({ id: 'topics.title' })}`}
            twitterDescription={formatMessage({ id: `${topicId}.description` })}
            url={formatMessage({ id: 'meta.url.topics' })}
          />
          <section className='section is-size-4-desktop is-size-5-touch'>
            <h1 className='title has-text-centered has-text-light'>{customTopic}</h1>
            <div className='container'>
              <hr />
              <div className='columns'>
                <div className='column is-half is-offset-one-quarter'>
                  {postsLocale.map(post => (
                    <RepoCard
                      key={post.fields.slug}
                      title={post.frontmatter.title}
                      subtitle={post.frontmatter.description.substring(0, 100)}
                      link={post.fields.slug}
                      image={
                        <img
                          style={{ borderRadius: '0.8em', border: '1em' }}
                          src={data.file.childImageSharp.fluid.src}
                          alt={post.fields.slug}
                          layout='constrained'
                          aspectRatio={data.file.childImageSharp.fluid.aspectRatio}
                          transformOptions={{
                            fit: 'cover',
                            cropFocus: 'attention',
                          }}
                          width='200px'
                          backgroundColor='white'
                        />
                      }
                      topics={post.frontmatter.topics}
                    />
                  ))}
                </div>
              </div>
            </div>
          </section>

          <div className='mt-6'>
            <p className='has-text-centered'>
              {formatMessage({ id: 'checkOut.topics' })}{' '}
              <Link to={getPath('topics')}>{formatMessage({ id: 'stack.topics' })}</Link>
            </p>
          </div>
        </Layout>
      )}
    </IntlProvider>
  )
}

TopicPost.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default TopicPost
